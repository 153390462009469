/* eslint-disable no-underscore-dangle */
/* eslint-disable no-underscore-dangle */
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-2 text-center"
          >
            <b-link class="text-center">
              <img
                :src="logoUrl"
                height="100"
                weight="80"
                alt=""
                srcset=""
              >
            </b-link>
            <br>
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('login.welcomeSubtitle') }}
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="formSubmit"
          >
            <!-- email -->
            <b-form-group
              :label="$t('form.email')"
              label-for="login-email"
            >
              <b-form-input
                id="login-email"
                v-model="$v.user.username.$model"
                :class="{
                  'is-invalid': $v.user.username.$error
                }"
                name="login-email"
                placeholder="toto@example.com"
              />
              <div
                v-if="$v.user.username.$error"
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.user.username.required"
                >{{ $t('errors.required') }}</span>
                <span
                  v-if="!$v.user.username.email"
                >{{ $t('errors.email') }}</span>
              </div>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t('form.password') }}</label>
                <b-link
                  :to="{name:'forgot-password'}"
                  class="text-danger"
                >
                  <small>{{ $t('login.forgot-passsword') }}</small>
                </b-link>
              </div>

              <b-input-group
                class="input-group-merge"
                :class="{ 'is-invalid': $v.user.password.$error }"
              >
                <b-form-input
                  id="login-password"
                  v-model="$v.user.password.$model"
                  class="form-control-merge"
                  :class="{ 'is-invalid': $v.user.password.$error }"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
                <div
                  v-if="$v.user.password.$error"
                  class="invalid-feedback"
                >
                  <span
                    v-if="!$v.user.password.required"
                  >{{ $t('errors.required') }}</span>
                </div>
              </b-input-group>

            </b-form-group>

            <!-- submit buttons -->
            <b-button
              variant="primary"
              type="submit"
              :disabled="process || verifyProcess"
              block
            >
              <b-spinner
                v-if="process || verifyProcess"
                small
              />
              <span
                v-show="!process || !verifyProcess"
                class="ml-1"
              >{{ $t('login.sign-in') }}</span>
            </b-button>
          </b-form>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('login.new-on-platform') }} </span>
            <b-link
              :to="{name:'register'}"
              class="text-danger font-weight-nold"
            >
              <span>&nbsp;{{ $t('login.create-account') }}</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text text-primary font-weight-bold">
              Godwin Multiservice
            </div>
          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { getPrivileges } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {
        username: null,
        password: null,
      },
      sideImg: require('@/assets/images/pages/login-exchange.svg'),
      logoImg: require('@/assets/images/logo/logo-sombre.svg'),
    }
  },
  validations: {
    user: {
      username: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      process: 'loginProcess',
      error: 'loginError',
      success: 'loginSuccess',

      verifyProcess: 'verifyEmailProcess',
      verifyError: 'verifyEmailError',
      verifySuccess: 'verifyEmailSuccess',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-exchange.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoImg = require('@/assets/images/logo/logo-sombre.svg')
        return this.logoImg
      }
      return this.logoImg
    },
  },
  watch: {
    success(val) {
      if (val) {
        switch (getPrivileges()) {
          case 'admin':
            this.$router.push({ name: 'admin-home' })
            break
          default:
            this.$router.push({ name: 'client-home' })
            break
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('login.connexion'),
            text: this.$t('login.connexion-success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        switch (val.status) {
          case 405:
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('login.connexion'),
                text: this.$t('login.bad-credentials'),
                icon: 'UserXIcon',
                variant: 'warning',
              },
            })
            break

          default:
            if (this.$store.state.auth.isLocked) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('login.connexion'),
                  text: this.$store.state.auth.isLocked,
                  icon: 'UserXIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('login.connexion'),
                  text: this.$t('login.connexion-error'),
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
            break
        }
      }
    },
    verifySuccess(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('login.connexion'),
            text: this.$t('login.unconfirmed'),
            icon: 'MailIcon',
            variant: 'warning',
          },
        })
      }
    },
    verifyError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('login.connexion'),
            text: this.$t('login.error-unconfirmed'),
            icon: 'MailIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['loginUser', 'addAdmin']),
    createAdmin() {
      this.addAdmin()
    },
    formSubmit() {
      this.$v.$touch()
      if (this.$v.$error) return
      this.loginUser({
        username: this.user.username,
        password: this.user.password,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
